<!--  -->
<template>
    <div class="backupCopy">
        <div class="dataShow-conters-nav" @scroll="navScroll">
            <div :class="item.active?'nav-item-active':'dataShow-conters-nav-item'" v-for="item,index in navList" :key="index" @click="seriesDataFn(item,index)">
                {{ item.T_name }}
            </div>
        </div>
        <div class="dataShow-conters-table">
            <el-date-picker v-model="TimeRange" v-if="!alldownling" type="daterange" range-separator="至"
            style="margin-bottom: 10px;"
                :picker-options="picOption" @change="changTimerange" value-format='yyyy-MM-dd'
                :clearable="false"
                start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
            <div v-if="alldownling" data-aos="zoom-in" data-aos-delay="0" style="flex:1;display: flex;align-items: center;justify-content: center;flex-direction: column;">
                <div style="display: flex;align-items: flex-end;justify-content: center;">
                    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
                        <el-progress type="circle" :width='100' :percentage="100" :format="format">
                        </el-progress>
                        <div style="margin-top: 5px;color: #5fc5ff;font-size: 12px;font-weight: 500;">备份总数</div>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
                        <el-progress type="dashboard" :width='150' :percentage="percentage" :format="formatall" :color="colors"></el-progress>
                        <div style="margin-top: 5px;color: #5fc5ff;font-size: 12px;font-weight: 500;">当前进度</div>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
                        <el-progress type="circle" :width='100' :percentage="100" :format="format1"></el-progress>
                        <div style="margin-top: 5px;color: #5fc5ff;font-size: 12px;font-weight: 500;">备份成功</div>
                    </div>
                </div>
                <el-button type="primary" :disabled="percentage==100?false:true" @click="alldownling = false" style="margin-top: 50px;">
                   {{ percentage==100?'我知道了':'正在备份..'}}
                </el-button>
            </div>
            <el-table v-if="!alldownling" :data="BacktableData" style="width: 100%" ref="multipleTable" max-height="600px">
                <el-table-column label="设备名称" prop="T_name" :show-overflow-tooltip="true" width="180">
                </el-table-column>
                <el-table-column label="数据量" prop="T_num" :show-overflow-tooltip="true" width="100">
                </el-table-column>
                <el-table-column label="备份时间" prop="T_date" :show-overflow-tooltip="true" width="180">
                </el-table-column>
                <el-table-column>
                    <template slot="header" slot-scope="scope">
                        <el-button type="primary" size="mini" :disabled="BacktableData.length==0?true:false" @click="dowAllFun(scope)">全部下载</el-button>
                    </template>
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="dowsingFun(scope.row)">下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <my-pagination v-if="!alldownling" style="margin-top: 20px;" :total="totalBack" :pageSizi="BackUppage.page_z"
            @currentChange="handleCurrentChange" :PageNum.sync="BackUppage.page"></my-pagination>
        </div>
    </div>
</template>

<script>
import {
		Timerange
	} from '@/utils/Times.js'
import {DevSenList } from "@/api/dataDisplay/chartShow"
import {
		DataBackUp, //列表
		DataBackUpPDF //下载
	} from '@/api/dataDisplay/dataShow.js'
import {
    Tsnid
} from '@/utils/sinIDFun.js';
import Axios from 'axios'
import fileDownload from 'js-file-download';
export default {
    data() {
        return {
            colors: [{
                    color: '#f56c6c',
                    percentage: 20
                },
                {
                    color: '#e6a23c',
                    percentage: 40
                },
                {
                    color: '#5cb87a',
                    percentage: 60
                },
                {
                    color: '#1989fa',
                    percentage: 80
                },
                {
                    color: '#67C23A',
                    percentage: 100
                },
            ],
            alldownling:false,

            dataAll:0,
            datasucc:0,
            percentage:0,
            navList:[],
            chartsMenu:{
                T_name: '',
                T_calssid:  '',
                page: 1,
                page_z: 20,
            },
            navsize:0,//总分页
            total:0,

            BacktableData:[],
            totalBack:0,
            BackUppage: {
                T_snid: '',
                Time_start: '',
                Time_end: '',
                page: 1,
                page_z:8,
            },
            picOption: {
                disabledDate(time) {
                    let AtTime = new Date().getTime(); //获取此时的时间戳
                    return (
                        time.getTime() < AtTime - (86400000 * 40) //小于当前时间40天的全部disabled掉
                        ||
                        time.getTime() > AtTime //大于当前时间的全部disabled掉
                    );
                }
            },
            TimeRange: [
					Timerange(new Date().getTime() - (86400000 * 40)), Timerange(new Date())
				],
        }
    },
    mounted(){
        this.getDevSenListApi()
    },
    methods: {
        formatall(){
            if(this.percentage!=100){
                return `${this.percentage}%`
            }else{
                return `备份完成`
            }
        },
        format() {
            return `${this.dataAll}条`
        },
        format1() {
            return `${this.datasucc}条`
        },
        async dowAllFun() { //全部下载
            this.alldownling = true
            this.dataAll = 0
            this.datasucc = 0
            this.percentage = 0 //导入进度
            var obj = {...this.BackUppage} 
            obj.page_z = 999999
            var arr = []
            var resIt = await this.AllListApi(obj)
            if(resIt.data.Code==200){
                arr = resIt.data.Data.Data
                this.dataAll = resIt.data.Data.Num
            }
            for (let i = 0; i < arr.length; i++) {
                var resIt = await this.dowupPdf(arr[i])
                ////console.log('全部下载',resIt)
                let arr1 = resIt.data.Data.split('/')
                ////console.log('全部下载',arr1,arr1[arr1.length-1])
                if (resIt.data.Code === 200) {
                    Axios({
                        method: 'get',
                        url: resIt.data.Data,
                        responseType: 'blob'
                    }).then(resBlob => {
                        if(resBlob.status===200){
                            this.datasucc = this.datasucc+1
                        }
                        this.percentage = parseFloat(Number((this.datasucc / this.dataAll) * 100)).toFixed(0) - 0
                        const FileSaver = require('file-saver')
                        const blob = new Blob([resBlob.data], {
                            type: 'application/pdf;charset=utf-8'
                        }) // 此处type根据你想要的
                        FileSaver.saveAs(blob, arr1[arr1.length-1]) // 下载的name文件名
                    });
                }
            }
        },
        AllListApi(obj) {
				return new Promise(resolve=>{
					DataBackUp(obj).then(res => {
						resolve(res)
					})
				})
			},
        async dowsingFun(e) { //单个下载
            const loading = this.$loading({
                lock: true,
                text: '正在整理数据，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            var resIt = await this.dowupPdf(e)
            let arr = resIt.data.Data.split('/')
            if (resIt.data.Code === 200) {
                Axios({
                    method: 'get',
                    url: resIt.data.Data,
                    responseType: 'blob'
                }).then(resBlob => {
                    const FileSaver = require('file-saver')
                    const blob = new Blob([resBlob.data], {
                        type: 'application/pdf;charset=utf-8'
                    }) // 此处type根据你想要的
                    FileSaver.saveAs(blob, arr[arr.length-1]) // 下载的name文件名
                });
            }
            loading.close()
        },
        dowupPdf(obj) {
            return new Promise(resolve => {
                DataBackUpPDF({
                    T_sn: obj.T_sn,
                    T_id: obj.T_id,
                    T_date: obj.T_date,
                }).then(res => {
                    setTimeout(function(){
                        resolve(res)
                    },1000)
                })
            })
        },
        changTimerange(e) { //时间选择
            this.BackUppage.page=1
            if (e != null) {
                this.BackUppage.Time_start = e[0]
                this.BackUppage.Time_end = e[1]
            } else {
                this.TimeRange = [Timerange(new Date().getTime() - (86400000 * 40)), Timerange(new Date())]
                this.$emit("pick",this.TimeRange)
                this.BackUppage.Time_start = Timerange(new Date().getTime() - (86400000 * 40))
                this.BackUppage.Time_end = Timerange(new Date())
            }
            this.GetbackListApi()
        },
        handleCurrentChange(data){
            this.BackUppage.page = data
            this.GetbackListApi()
        },
        GetbackListApi() {
            DataBackUp(this.BackUppage).then(res => {
                if (res.data.Code === 200) {
                    this.BacktableData = res.data.Data.Data || []
                    this.totalBack = res.data.Data.Num
                }
            })
        },
        async navScroll(e){//滚动加载导航
            let Hitbottom = e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight
            if (Hitbottom && this.chartsMenu.page < this.navsize) {
                let height = e.target.scrollTop
                e.target.scrollTop = height
                this.chartsMenu.page++
                await this.getDevSenListApi()
            }else if(Hitbottom && this.chartsMenu.page>this.navsize){
                this.$message.info('已经到底了')
                return
            }
        },
        async getDevSenListApi(){
            let reslut = await DevSenList(this.chartsMenu)
            this.total = reslut.data.Data.Num
            this.navsize = reslut.data.Data.Page_size
            let arr = reslut.data.Data.DeviceSensor_lite || []
            arr.forEach(item=>{
                item.active = false
            })
            this.navList = [...this.navList,...arr]
        },
        async seriesDataFn(item,index){//点击
            this.BackUppage.Time_start = this.TimeRange[0]
				this.BackUppage.Time_end = this.TimeRange[1]
            this.navList[index].active = !this.navList[index].active
            const arrData = this.navList.filter(item => item.active == true)//哪些选中的
            if(arrData.length!=0){
                this.BackUppage.T_snid = Tsnid(arrData)
                this.BackUppage.page = 1
                this.GetbackListApi()
            }else{
                this.BacktableData = []
            }
        }
    }
}
</script>
<style scoped>
::v-deep .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    border-bottom: none;
    border-bottom: 1px solid rgba(133, 170, 233, 0.08);
}

::v-deep .el-table__cell.is-leaf {
    border-bottom: 1px solid rgba(133, 170, 233, 0.08) !important;
}

/* 设置 el-table 的背景色为透明 */
::v-deep .el-table {
    background-color: transparent !important;
    border:1px solid rgba(133, 170, 233, 0.1);
}

/* 设置 el-table 的表头背景色 */
::v-deep .el-table__header-wrapper th {
    background-color: rgba(133, 170, 233, 0.08) !important;
    color: #03aff6;
}

::v-deep .el-form-item__content {
    line-height: 0;
}

::v-deep .el-table tr {
    background-color: transparent !important;

}

/* 设置表格行的背景色为透明 */
::v-deep .el-table__body tr {
    background-color: transparent !important;
    color: #03aff6;
}

/* 如果需要，也可以设置鼠标悬停时的行背景色为透明 */
::v-deep .el-table__body tr:hover>td {
    background-color: rgba(133, 170, 233, 0.08) !important;
}

::v-deep .el-table__cell {
    /* border-bottom: 1px solid rgba(133, 170, 233, 0.08); */
}


/**去掉table底部的横线 */
::v-deep .el-table::before {
    height: 0;
}


::v-deep .el-checkbox__inner {
    background-color: transparent !important;
    border: 1px solid #03aff6;
}

/* 修改勾选框的伪元素颜色（如果需要） */
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    border-color: #03aff6;
    /* 勾选标记的颜色 */
}


::v-deep .el-date-editor .el-range-input {
    background: #183d7d;
    color: #03aff6;
}
::v-deep .el-date-editor .el-range-separator {
    color: #03aff6;
}
</style>
<style lang="scss">
.backupCopy{
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 10px;
    gap: 20px;
    overflow: hidden;
}
.dataShow-conters-table{
    padding: 10px;
}
</style>