// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	ClassList: '/v3/Class/All',//select下拉数据
	DevSenList: '/v3/Data/Device_Sensor_List',//左侧菜单列表
	DataList: '/v3/Data/List',//表格数据
	DataChartPdf: '/v3/Data/ChartShow_PDF',//表格数据
}
//左侧菜单列表
export function DataChartPdf(data) {
	return axios({
		url: ClassApi.DataChartPdf,
		method: 'post',
		data:data
	})
}
//左侧菜单列表
export function DataList(data) {
	return axios({
		url: ClassApi.DataList,
		method: 'post',
		data:data
	})
}

//左侧菜单列表
export function DevSenList(data) {
	return axios({
		url: ClassApi.DevSenList,
		method: 'post',
		data:data
	})
}
//select下拉数据
export function ClassList(data) {
	return axios({
		url: ClassApi.ClassList,
		method: 'post',
		data:data
	})
}