// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	ClassList: '/v3/Class/All',//select下拉数据
	DevSenList: '/v3/Data/Device_Sensor_List',//左侧菜单列表
	DataList: '/v3/Data/List',//表格数据
	DataExcel: '/v3/Data/Excel',//导出Excel
	DataPDF: '/v3/Data/PDF',//导出PDF
	DataBackUp: '/v3/Data/BackUp',//
	DataBackUpPDF: '/v3/Data/BackUp_PDF',//
}
//导出Excel
export function DataBackUpPDF(data) {
	return axios({
		url: ClassApi.DataBackUpPDF,
		method: 'post',
		data:data
	})
}

//导出PDF
export function DataBackUp(data) {
	return axios({
		url: ClassApi.DataBackUp,
		method: 'post',
		data:data
	})
}
//导出Excel
export function DataExcel(data) {
	return axios({
		url: ClassApi.DataExcel,
		method: 'post',
		data:data
	})
}

//导出PDF
export function DataPDF(data) {
	return axios({
		url: ClassApi.DataPDF,
		method: 'post',
		data:data
	})
}


//左侧菜单列表
export function DataList(data) {
	return axios({
		url: ClassApi.DataList,
		method: 'post',
		data:data
	})
}
//左侧菜单列表
export function DevSenList(data) {
	return axios({
		url: ClassApi.DevSenList,
		method: 'post',
		data:data
	})
}
//select下拉数据
export function ClassList(data) {
	return axios({
		url: ClassApi.ClassList,
		method: 'post',
		data:data
	})
}